<template>
  <v-row align="center" justify="center">
    <v-col cols="12" md="8">
      <v-card class="pa-4 mb-4">
        <div class="red--text">
          Промокоды - это хороший инструмент для продвижения вашей книги.
          Используйте промокоды разумно, не тратьте все на старте продаж.
          Внимание! Использование промокодов в качестве вознаграждения за
          какие-либо действия пользователей, как например: лайк книги,
          комментарий к книге или записи блога, вступление в группу соцсети,
          подписка на авторскую страницу - <b>не допускается</b>.
          <!-- Подробнее о промокодах читайте в этом разделе Справки -->
        </div>
      </v-card>
      <v-card class="table-promo pa-4 mb-4">
        <v-btn
          :loading="generateLoading"
          :disabled="stopGenerate"
          color="primary"
          @click="generatePromo"
          >Сгенерировать промокод</v-btn
        >
        <div v-if="stopGenerate" class="stop-generate mt-2">
          Генерация промокодов будет доступна через {{ promoDays }} дней
        </div>

        <v-row v-if="!promoCodes || promoCodes.length == 0" align="center">
          <no-content route-name title="У вас нет промокодов"></no-content>
        </v-row>
        <v-data-table
          v-else
          :headers="headersTable"
          :items="promoCodes"
          :items-per-page="15"
          class=""
          item-key="id_table"
        >
          <template v-slot:item.status_code="{ item }">
            <v-chip :color="getStatusColor(item.status_code)" dark>
              {{ getStatusText(item.status_code) }}
            </v-chip>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data: () => ({
    generateLoading: false,
    headersTable: [
      {
        text: "#",
        align: "start",
        // sortable: false,
        value: "number",
      },
      { text: "Промокод", value: "code" },
      { text: "Статус", value: "status_code" },
      { text: "Действителен до", value: "date_to" },
    ],
  }),
  methods: {
    getStatusColor(status_code) {
      if (!status_code) return "green";
      else if (status_code == 1) return "red";
      else return "orange";
    },
    getStatusText(status_code) {
      if (!status_code) return "Неиспользован";
      else if (status_code == 1) return "Использован";
      else if (status_code == 2) return "Просрочен";
    },
    generatePromo() {
      let app = this;
      app.generateLoading = true;
      this.$store
        .dispatch("cabinet_promo/generatePromo", {
          book_id: app.book.id,
        })
        .then(() => {
         app.generateLoading = false;
        }).catch(() => {
          app.generateLoading = false;
        });
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      loadPage: "loadPage",
      book: "cabinet_books/book",
      promoCodes: "cabinet_promo/promoCodes",
      promoDays: "cabinet_promo/promoDays",
    }),
    stopGenerate() {
      let result = false;
      if (this.promoDays && this.promoDays > 0) {
        result = true;
      }

      return result;
    },
  },
};
</script>