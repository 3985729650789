<template>
  <div>
    <v-row align="center" justify="center">
      <v-col cols="12" md="8">
        <v-form v-model="valid" ref="form">
          <!-- <v-radio-group v-if="allEroFilters.eroContact && allEroFilters.eroContact.items" class="dop-filters-group" v-model="eroContact">
            <p class="flter-header">{{ allEroFilters.eroContact.header }} <span class="primary--text" @click="eroContact = null">[очистить]</span></p>
            <v-radio
              v-for="(item, index) in allEroFilters.eroContact.items"
              :key="index"
              :index="index"
              :label="item"
              :value="index"
            ></v-radio>
          </v-radio-group> -->

          <v-radio-group v-if="allEroFilters.contact && allEroFilters.contact.items" class="dop-filters-group" v-model="contact">
            <p class="flter-header">{{ allEroFilters.contact.header }} <span class="primary--text" @click="contact = null">[очистить]</span></p>
            <v-radio
              v-for="(item, index) in allEroFilters.contact.items"
              :key="index"
              :index="index"
              :label="item"
              :value="index"
            ></v-radio>
          </v-radio-group>

          <v-radio-group
            class="dop-filters-group"
            v-if="contact == '1' && allEroFilters.ageLovers && allEroFilters.ageLovers.items"
            v-model="ageLovers"
          >
            <p class="flter-header">{{ allEroFilters.ageLovers.header }} <span class="primary--text" @click="ageLovers = null">[очистить]</span></p>
            <v-radio
              v-for="(item, index) in allEroFilters.ageLovers.items"
              :key="index"
              :index="index"
              :label="item"
              :value="index"
            ></v-radio>
          </v-radio-group>

          <div class="dop-filters-group" v-if="allEroFilters.natureContact && allEroFilters.natureContact.items">
            <p class="flter-header">{{ allEroFilters.natureContact.header }} <span class="primary--text" @click="natureContact = []">[очистить]</span></p>
            <v-checkbox
              v-model="natureContact"
              :disabled="natureContactDisabled(index)"
              v-for="(item, index) in allEroFilters.natureContact.items"
              :key="index"
              :index="index"
              :label="item"
              :value="index"
            ></v-checkbox>
          </div>

          <v-radio-group class="dop-filters-group" v-if="allEroFilters.heroType && allEroFilters.heroType.items" v-model="heroType">
            <p class="flter-header">{{ allEroFilters.heroType.header }} <span class="primary--text" @click="heroType = null">[очистить]</span></p>
            <v-radio
              v-for="(item, index) in allEroFilters.heroType.items"
              :key="index"
              :index="index"
              :label="item"
              :value="index"
            ></v-radio>
          </v-radio-group>

          <v-radio-group class="dop-filters-group" v-if="allEroFilters.socialStatus && allEroFilters.socialStatus.items" v-model="socialStatus">
            <p class="flter-header">{{ allEroFilters.socialStatus.header }} <span class="primary--text" @click="socialStatus = null">[очистить]</span></p>
            <v-radio
              v-for="(item, index) in allEroFilters.socialStatus.items"
              :key="index"
              :index="index"
              :label="item"
              :value="index"
            ></v-radio>
          </v-radio-group>

          <v-radio-group class="dop-filters-group" v-if="allEroFilters.ageHero && allEroFilters.ageHero.items" v-model="ageHero">
            <p class="flter-header">{{ allEroFilters.ageHero.header }} <span class="primary--text" @click="ageHero = null">[очистить]</span></p>
            <v-radio
              v-for="(item, index) in allEroFilters.ageHero.items"
              :key="index"
              :index="index"
              :label="item"
              :value="index"
            ></v-radio>
          </v-radio-group>

          <div class="dop-filters-group" v-if="allEroFilters.heroineType && allEroFilters.heroineType.items">
            <p class="flter-header">{{ allEroFilters.heroineType.header }} <span class="primary--text" @click="heroineType = []">[очистить]</span></p>
            <v-checkbox
              v-model="heroineType"
              :disabled="heroineTypeDisabled(index)"
              v-for="(item, index) in allEroFilters.heroineType.items"
              :key="index"
              :index="index"
              :label="item"
              :value="index"
            ></v-checkbox>
          </div>

          <v-radio-group class="dop-filters-group" v-if="allEroFilters.ageHeroine && allEroFilters.ageHeroine.items" v-model="ageHeroine">
            <p class="flter-header">{{ allEroFilters.ageHeroine.header }} <span class="primary--text" @click="ageHeroine = null">[очистить]</span></p>
            <v-radio
              v-for="(item, index) in allEroFilters.ageHeroine.items"
              :key="index"
              :index="index"
              :label="item"
              :value="index"
            ></v-radio>
          </v-radio-group>

          <v-radio-group class="dop-filters-group" v-if="allEroFilters.heroineinPosition && allEroFilters.heroineinPosition.items" v-model="heroineinPosition">
            <p class="flter-header">{{ allEroFilters.heroineinPosition.header }} <span class="primary--text" @click="heroineinPosition = null">[очистить]</span></p>
            <v-radio
              v-for="(item, index) in allEroFilters.heroineinPosition.items"
              :key="index"
              :index="index"
              :label="item"
              :value="index"
            ></v-radio>
          </v-radio-group>

          <v-radio-group class="dop-filters-group" v-if="allEroFilters.heroWealth && allEroFilters.heroWealth.items" v-model="heroWealth">
            <p class="flter-header">{{ allEroFilters.heroWealth.header }} <span class="primary--text" @click="heroWealth = null">[очистить]</span></p>
            <v-radio
              v-for="(item, index) in allEroFilters.heroWealth.items"
              :key="index"
              :index="index"
              :label="item"
              :value="index"
            ></v-radio>
          </v-radio-group>

          <v-radio-group class="dop-filters-group" v-if="allEroFilters.heroineWealth && allEroFilters.heroineWealth.items" v-model="heroineWealth">
            <p class="flter-header">{{ allEroFilters.heroineWealth.header }} <span class="primary--text" @click="heroineWealth = null">[очистить]</span></p>
            <v-radio
              v-for="(item, index) in allEroFilters.heroineWealth.items"
              :key="index"
              :index="index"
              :label="item"
              :value="index"
            ></v-radio>
            
          </v-radio-group>
           <v-btn dark color="primary" :loading="submitLoader" @click="submit"
            >Обновить</v-btn
          >
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data: () => ({
    valid: true,
    submitLoader: false,
    // eroContact: null,
    contact: null,
    ageLovers: null,
    ageHero: null,
    ageHeroine: null,
    heroineinPosition: null,
    heroWealth: null,
    heroineWealth: null,
    natureContact: [],
    heroType: null,
    heroineType: [],
    socialStatus: null,
  }),
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    submit() {
      if (!this.validate()) return;
      let app = this;
      app.submitLoader = true;
      //console.log(app.coauthor);
      this.$store
        .dispatch("cabinet_books/addEroFilters", {
          book_id: app.book.id,
          // eroContact: app.eroContact,
          contact: app.contact,
          ageLovers: app.ageLovers,
          ageHero: app.ageHero,
          ageHeroine: app.ageHeroine,
          heroineinPosition: app.heroineinPosition,
          heroWealth: app.heroWealth,
          heroineWealth: app.heroineWealth,
          natureContact: app.natureContact,
          heroType: app.heroType,
          heroineType: app.heroineType,
          socialStatus: app.socialStatus,
        })
        .then(() => {
          app.submitLoader = false;
        })
        .catch(() => {
          app.submitLoader = false;
        });
    },
    natureContactDisabled(value) {
      //  console.log(this.natureContact.includes(value));
      if (this.natureContact.length > 2) {
        if (!this.natureContact.includes(value)) {
          return true;
        }
      }
      return false;
    },
    heroineTypeDisabled(value) {
      //  console.log(this.natureContact.includes(value));
      if (this.heroineType.length > 1) {
        if (!this.heroineType.includes(value)) {
          return true;
        }
      }
      return false;
    },
    updateData(){
      if(this.book && this.book.eroFiltersToEdit){
        // this.eroContact = this.book.eroFiltersToEdit.eroContact ? this.book.eroFiltersToEdit.eroContact : null;
        this.contact = this.book.eroFiltersToEdit.contact ? this.book.eroFiltersToEdit.contact : null;
        this.ageLovers = this.book.eroFiltersToEdit.ageLovers ? this.book.eroFiltersToEdit.ageLovers : null;
        this.ageHero = this.book.eroFiltersToEdit.ageHero ? this.book.eroFiltersToEdit.ageHero : null;
        this.ageHeroine = this.book.eroFiltersToEdit.ageHeroine ? this.book.eroFiltersToEdit.ageHeroine : null;
        this.heroineinPosition = this.book.eroFiltersToEdit.heroineinPosition ? this.book.eroFiltersToEdit.heroineinPosition : null;
        this.heroWealth = this.book.eroFiltersToEdit.heroWealth ? this.book.eroFiltersToEdit.heroWealth : null;
        this.heroineWealth = this.book.eroFiltersToEdit.heroineWealth ? this.book.eroFiltersToEdit.heroineWealth : null;
        this.natureContact = this.book.eroFiltersToEdit.natureContact ? this.book.eroFiltersToEdit.natureContact : [];
        this.heroType = this.book.eroFiltersToEdit.heroType ? this.book.eroFiltersToEdit.heroType : null;
        this.heroineType = this.book.eroFiltersToEdit.heroineType ? this.book.eroFiltersToEdit.heroineType : [];
        this.socialStatus = this.book.eroFiltersToEdit.socialStatus ? this.book.eroFiltersToEdit.socialStatus : null;
      }
    }
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      loadPage: "loadPage",
      allEroFilters: "allEroFilters",
      book: "cabinet_books/book",
      // eroFiltersToEdit: "cabinet_books/eroFiltersToEdit",

      // chapters: "cabinet_chapters/chapters",
    }),
  },
   watch: {
    book() {
      if (this.book) {
        console.log("что то изменилось")
        this.updateData();
      }
    }
   },
   mounted(){
      this.updateData();
   }
};
</script>