<template>
  <div v-if="book" id="sale-book">
    <v-subheader v-if="salesAllowed" class="px-0 success--text"
      >Продажи разрешены</v-subheader
    >
    <v-btn v-else @click="checkSale" class="success"
      > {{ book.finish ? 'Запрос на продажу книги' : 'Запрос на продажу подписки' }}</v-btn
    >

    <v-row v-if="+book.paid">
      <v-btn
        dark
        color="primary"
        class="mr-4"
        @click="showPriceForm = !showPriceForm"
        >Запрос на изменение цены</v-btn
      ><v-btn dark color="primary" @click="removeSale">Снять с продажи</v-btn>
    </v-row>
    <v-form v-if="showPriceForm" v-model="validPrice" ref="formPrice">
      <v-subheader class="pl-0">Ввведите новую цену</v-subheader>
      <v-slider
        v-model="new_price"
        min="50"
        max="150"
        :rules="priceRules"
        :label="new_price + ' руб.'"
      >
      </v-slider>
      <v-btn dark color="primary" @click="changePrice">Отправить запрос</v-btn>
    </v-form>

    <v-dialog v-model="dialogRequest" class="text-center" max-width="640">
      <v-card class="pa-4">
        <v-card-title class="headline justify-center"
          >Вам необходимо выполнить условия!</v-card-title
        >

        <ul>
          <li v-if="!book.sale_free_books" class="error--text">
            Бесплатная книга на {{ book.count_chars_free_book }} символов
          </li>
          <li v-if="!book.sale_count_char" class="error--text">
            Текущая книга не должна быть менее
            {{ book.count_chars_in_sale_book }} символов
          </li>
        </ul>
        <v-card-actions class="justify-space-around">
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="dialogRequest = false"
            >Закрыть</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data: () => ({
    validPrice: true,
    dialogRequest: false,
    validRequest: true,
    sales_email: "",
    sales_phone: "",
    emailRules: [
      (v) => !!v || "Поле обязательно",
      (v) => /.+@.+\..+/.test(v) || "Не верно введен E-mail",
    ],
    showPriceForm: false,
    priceRules: [
      (v) => v >= 50 || "Цена должна быть от 50 руб. до 150 руб.",
      (v) => v <= 150 || "Цена должна быть от 50 руб. до 150 руб.2",
    ],
    new_price: 0, //Цена
  }),
  methods: {
    validatePrice() {
      return this.$refs.formPrice.validate();
    },
    validateRequest() {
      return this.$refs.formRequest.validate();
    },
    checkSale() {
      if (this.requestAllowed) {
        this.$router.push({
          name: "AppendixDetail",
          params: { id: this.book.id },
        });
      } else {
        this.dialogRequest = true;
      }
    },
    changePrice() {
      if (!this.validatePrice()) return;
      let app = this;
      this.$store
        .dispatch("cabinet_books/changePrice", {
          book_id: app.book.id,
          new_price: app.new_price,
        })
        .then(() => {});
    },
    removeSale() {
      let app = this;
      this.$store
        .dispatch("cabinet_books/removeSale", {
          book_id: app.book.id,
        })
        .then(() => {});
    },
  },

  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      book: "cabinet_books/book",

      // chapters: "cabinet_chapters/chapters",
    }),
    salesAllowed() {
      if (this.book) {
        return +this.book.sales_allowed;
      }
      return false;
    },
    requestAllowed() {
      if (this.book && this.book.sale_free_books && this.book.sale_count_char) {
        return true;
      }
      return false;
    },
  },
  mounted() {
    if (this.currentUser && !this.sales_email) {
      this.sales_email = this.currentUser.email;
    }
  },
};
</script>


  