<template>
  <v-row align="center" justify="center">
    <v-col cols="12" md="8">
      <v-card class="pa-4 mb-4">
        <v-form v-model="valid" ref="form">
          <v-menu
            ref="menuDate"
            v-model="menuDate"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            :min-width="$vuetify.breakpoint.xsOnly ? '100%' : '290px'"
          >
            <!-- :return-value.sync="date" -->
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="dateToPublish"
                label="Дата"
                prepend-icon="$vuetify.icons.calendar"
                readonly
                v-on="on"
                :rules="requiredRules"
              ></v-text-field>
            </template>
            <v-date-picker
              :first-day-of-week="1"
              v-model="dateToPublish"
              no-title
              :min="minDate.toISOString().substr(0, 10)"
              :full-width="$vuetify.breakpoint.xsOnly"
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menuDate = false"
                >Закрыть</v-btn
              >
              <v-btn
                text
                color="primary"
                @click="$refs.menuDate.save(dateToPublish)"
                >Принять</v-btn
              >
            </v-date-picker>
          </v-menu>
          <v-subheader class="pl-0">% скидки.</v-subheader>
          <v-slider v-model="percent" min="5" max="35" :label="percent + ' %.'">
          </v-slider>
          <v-btn
            @click="sendDiscount"
            class=""
            color="primary"
            dark
            :loading="addLoading"
            >Отправить</v-btn
          >
        </v-form>
      </v-card>
      <v-card class="table-promo pa-4 mb-4">
        <v-row v-if="!discounts || discounts.length == 0" align="center">
          <no-content
            route-name
            title="У вас нет назначенных скидок"
          ></no-content>
        </v-row>
        <v-data-table
          v-else
          :headers="headersTable"
          :items="discounts"
          :items-per-page="15"
          class=""
          item-key="id_table"
        >
          <template v-slot:item.book="{ item }">
            <router-link
              :to="{ name: 'BookShow', params: { slug: item.book.slug } }"
            >
              {{ item.book.title }}
            </router-link>
          </template>
          <template v-slot:item.percent="{ item }">
            {{ item.percent }}%
          </template>
          <template v-slot:item.actions="{ item }">
            <v-btn icon small :loading="dellLoading" @click="deleteItem(item)">
              <v-icon small> $vuetify.icons.delete </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data: () => ({
    valid: true,
    addLoading: false,
    dellLoading: false,
    headersTable: [
      {
        text: "Книга",
        align: "start",
        // sortable: false,
        value: "book",
      },
      { text: "Дата скидки", value: "date_format" },
      { text: "Скидка", value: "percent" },
      { text: "", value: "actions", sortable: false, align: "end", },
    ],
    percent: 10,
    menuDate: false,
    dateToPublish: "",
    requiredRules: [(v) => !!v || "Поле обязательно"],
  }),
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    sendDiscount() {
      if (!this.validate()) return;
      let app = this;
      app.addLoading = true;
      this.$store
        .dispatch("cabinet_promo/sendDiscount", {
          book_id: app.book.id,
          percent: app.percent,
          dateToPublish: app.dateToPublish,
        })
        .then(() => {
         app.addLoading = false;
        }).catch(() => {
          app.addLoading = false;
        });
    },
    deleteItem(item) {
      let app = this;
      app.dellLoading = true;
      this.$store
      .dispatch("cabinet_promo/dellDiscount", {
          id: item.id,
        })
        .then(() => {
         app.dellLoading = false;
        }).catch(() => {
          app.dellLoading = false;
        })
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      loadPage: "loadPage",
      book: "cabinet_books/book",
      discounts: "cabinet_promo/discounts",
    }),
    minDate() {
      // Create new Date instance
      let date = new Date();

      // Add a day
      date.setDate(date.getDate() + 1);
      return date;
    },
  },
};
</script>