var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-card',{staticClass:"pa-4 mb-4"},[_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-menu',{ref:"menuDate",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":_vm.$vuetify.breakpoint.xsOnly ? '100%' : '290px'},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":"Дата","prepend-icon":"$vuetify.icons.calendar","readonly":"","rules":_vm.requiredRules},model:{value:(_vm.dateToPublish),callback:function ($$v) {_vm.dateToPublish=$$v},expression:"dateToPublish"}},on))]}}]),model:{value:(_vm.menuDate),callback:function ($$v) {_vm.menuDate=$$v},expression:"menuDate"}},[_c('v-date-picker',{attrs:{"first-day-of-week":1,"no-title":"","min":_vm.minDate.toISOString().substr(0, 10),"full-width":_vm.$vuetify.breakpoint.xsOnly},model:{value:(_vm.dateToPublish),callback:function ($$v) {_vm.dateToPublish=$$v},expression:"dateToPublish"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menuDate = false}}},[_vm._v("Закрыть")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menuDate.save(_vm.dateToPublish)}}},[_vm._v("Принять")])],1)],1),_c('v-subheader',{staticClass:"pl-0"},[_vm._v("% скидки.")]),_c('v-slider',{attrs:{"min":"5","max":"35","label":_vm.percent + ' %.'},model:{value:(_vm.percent),callback:function ($$v) {_vm.percent=$$v},expression:"percent"}}),_c('v-btn',{attrs:{"color":"primary","dark":"","loading":_vm.addLoading},on:{"click":_vm.sendDiscount}},[_vm._v("Отправить")])],1)],1),_c('v-card',{staticClass:"table-promo pa-4 mb-4"},[(!_vm.discounts || _vm.discounts.length == 0)?_c('v-row',{attrs:{"align":"center"}},[_c('no-content',{attrs:{"route-name":"","title":"У вас нет назначенных скидок"}})],1):_c('v-data-table',{attrs:{"headers":_vm.headersTable,"items":_vm.discounts,"items-per-page":15,"item-key":"id_table"},scopedSlots:_vm._u([{key:"item.book",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'BookShow', params: { slug: item.book.slug } }}},[_vm._v(" "+_vm._s(item.book.title)+" ")])]}},{key:"item.percent",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.percent)+"% ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","small":"","loading":_vm.dellLoading},on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" $vuetify.icons.delete ")])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }