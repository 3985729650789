<template>
  <div>
    <v-toolbar tile flat elevation="1">
      <v-btn :to="{ name: 'CabinetBooks' }" class="no-active" icon>
        <v-icon>$vuetify.icons.arrow_left</v-icon>
      </v-btn>
      <v-toolbar-title>{{ topTitle }}</v-toolbar-title>
      <v-spacer></v-spacer>
<v-spacer></v-spacer>
      <v-btn
        @click="deleteAllChaptersModal"
        v-if="bookTab == 'tab-chapters' && chapters && chapters.length > 0"
        text
        tile
        :loading="dellChapterLoader"
        >Удалить все</v-btn
      >
      <v-btn
        v-if="bookId"
        :to="{ name: 'CabinetChapterAdd', params: { bookId: bookId } }"
        text
        tile
        >{{
          $vuetify.breakpoint.smAndUp ? "Добавить главу" : "Добавить"
        }}</v-btn
      >
      <template v-if="bookId" v-slot:extension>
        <v-tabs v-model="bookTab" centered grow show-arrows>
          <v-tab href="#tab-edit">{{
            $vuetify.breakpoint.smAndUp ? "Настройки книги" : "Настройки"
          }}</v-tab>
          <v-tab href="#tab-chapters">Оглавление</v-tab>
          <v-tab href="#tab-coauthors">Соавторы</v-tab>
          <v-tab href="#tab-filters">Доп фильтры</v-tab>
          <v-tab class="green--text" v-if="paid" href="#tab-promo"
            >Промокоды</v-tab
          >
          <v-tab class="orange--text" v-if="paid" href="#tab-discount"
            >Скидка</v-tab
          >
          <v-tab v-if="paid" href="#tab-loyalty">Программа лояльности</v-tab>
        </v-tabs>
      </template>
    </v-toolbar>
    <v-container v-if="!loadPage" class="fill-height" fluid>
      <v-btn
        v-if="bookId"
        color="primary"
        :to="{ name: 'CabinetChapterAdd', params: { bookId: bookId } }"
        dark
        fixed
        bottom
        right
        fab
      >
        <v-icon>$vuetify.icons.plus</v-icon>
      </v-btn>
      <v-tabs-items class="flex-grow-1" v-model="bookTab">
        <v-tab-item value="tab-edit">
          <v-row align="center" justify="center">
            <v-col cols="12" md="8">
              <v-card class="elevation-0">
                <v-form v-model="valid" ref="form">
                  <v-row>
                    <!-- <div class="image-preview" v-if="imageData.length > 0"> -->

                    <v-col cols="12">
                      <v-btn
                        v-if="book"
                        class="mb-4"
                        :to="{ name: 'BookShow', params: { slug: book.slug } }"
                        >Просмотр книги</v-btn
                      >
                      <div
                        class="book-edit-head"
                        :class="
                          $vuetify.breakpoint.xsOnly
                            ? 'book-edit-head-mobile'
                            : ''
                        "
                      >
                        <div class="book-edit-left">
                          <div
                            @click="$refs.loadImage.click()"
                            class="image-preview"
                            v-bind:style="{
                              backgroundImage: 'url(' + imageData + ')',
                            }"
                          >
                            <v-icon>$vuetify.icons.file_upload</v-icon>
                            <input
                              ref="loadImage"
                              type="file"
                              accept="image/png, image/jpeg, image/bmp"
                              @change="uploadeImage"
                            />
                          </div>
                          <span>Разреш: 260x400 px</span>
                          <span>Макс: 2mb</span>
                        </div>
                        <div class="select-book-category">
                          <div class="select-book-category-tree">
                            <v-subheader>Жанр</v-subheader>

                            <v-treeview
                              dense
                              open-on-click
                              selectable
                              activatable
                              selection-type="independent"
                              :items="categories"
                              v-model="changecategories"
                              :selected-color="
                                categoriesError ? 'red' : 'primary'
                              "
                              open-all
                            >
                              <!-- <template slot="label" slot-scope="{ item }">
                              <div @click="clickCategory(item)">{{ item.name }}</div>
                              </template>-->
                            </v-treeview>
                            <!-- 
                             @update:active="clickCategory()"
                            open-all
                            selection-type="independent"
                            :active="changecategories"
                            @update:active="test"-->
                          </div>
                          <div v-if="categoriesError" class="categories-error">
                            Ошибка! Максимальное число категорий 4
                          </div>
                        </div>
                      </div>
                      <v-text-field
                        v-model="title"
                        name="inputTitle"
                        label="Название"
                        required
                        :disabled="paid ? true : false"
                        :rules="titleRules"
                      ></v-text-field>

                      <v-select
                        v-if="series.length > 0"
                        v-model="serie_id"
                        :items="series"
                        item-text="title"
                        item-value="id"
                        label="Цикл"
                      ></v-select>
                      <div v-else>
                        <v-subheader class="pl-0"
                          >Цикл (Чтобы прикрепить книгу к циклу нужно зайти в
                          раздел Циклы и создать цикл.)</v-subheader
                        >
                        <v-btn :to="{ name: 'CabinetSerieAdd' }"
                          >Добавить цикл</v-btn
                        >
                      </div>
                      <v-textarea
                        name="inputExcerpt"
                        label="Aннотация (не обязательно)"
                        value
                        v-model="excerpt"
                        :rules="excerptRules"
                      ></v-textarea>
                      <v-text-field
                        v-model="yt"
                        name="inputTitle"
                        label="Ссылка на видео youtube"
                        :rules="ytRules"
                      ></v-text-field>
                      <!-- <v-textarea
                        name="inputExcerpt"
                        label="Примечания автора (не обязательно)"
                        value
                        v-model="notes"
                        rows="5"
                      ></v-textarea>-->
                      <v-combobox
                        v-model="tags"
                        :items="alltags"
                        label="Теги"
                        multiple
                        chips
                        deletable-chips
                        :rules="tagsRules"
                        :delimiters="delimitersTags"
                        @change="onChangeTags"
                      ></v-combobox>
                      <!-- Отключили, так как пока будет стоять ссылка на донат -->

                      <sales-request></sales-request>

                      <!-- <v-checkbox
                        v-if="salesAllowed"
                        v-model="paid"
                        :false-value="0"
                        :true-value="1"
                        label="Продавать книгу"
                      ></v-checkbox> -->

                      <div v-if="paid">
                        <v-subheader class="pl-0"
                          >Цена: {{ price }} руб.</v-subheader
                        >
                        <!-- <v-slider
                          v-model="price"
                          min="50"
                          max="150"
                          :rules="priceRules"
                          :label="price + ' руб.'"
                        >
                        </v-slider> -->

                        <v-subheader class="px-0"
                          >Ознакомительный фрагмент (минимум 40 тыс.
                          символов)</v-subheader
                        >
                        <v-select
                          v-if="chapters_public.length > 0"
                          v-model="chpid"
                          :items="chapters_public"
                          label="Глава с которой начнется платный контент"
                          item-text="title"
                          item-value="id"
                        ></v-select>
                      </div>
                      <!-- <v-checkbox
                        v-model="fake_fragment"
                        :false-value="0"
                        :true-value="1"
                        label="Ознакомительный фрагмент"
                      ></v-checkbox> -->

                      <v-checkbox
                        v-model="adult"
                        :false-value="0"
                        :true-value="1"
                        label="Для взрослых (18+)"
                      ></v-checkbox>

                      <v-checkbox
                        v-model="can_comment"
                        :false-value="0"
                        :true-value="1"
                        label="Разрешить комментарии"
                      ></v-checkbox>
                      <!-- <v-checkbox
                        v-model="can_download"
                        :false-value="0"
                        :true-value="1"
                        label="Скачивание"
                      ></v-checkbox>-->
                      <v-checkbox
                        id="status"
                        v-if="bookId"
                        v-model="status"
                        :false-value="0"
                        :true-value="1"
                        :disabled="paid ? true : false"
                        label="Опубликовать"
                      ></v-checkbox>

                      <router-link
                        v-if="paid && !finish"
                        :to="{
                          name: 'AppendixDetail',
                          params: { id: bookId },
                          query: { complete: 1 },
                        }"
                        class="d-block mb-4"
                        >Завершить книгу</router-link
                      >

                      <v-checkbox
                        v-if="bookId && !paid"
                        v-model="finish"
                        :false-value="0"
                        :true-value="1"
                        label="Книга завершена"
                      ></v-checkbox>
                      <!-- <v-checkbox
                        @change="openBookFinishModal"
                        v-if="bookId && !paid"
                        v-model="finish"
                        :false-value="0"
                        :true-value="1"
                        label="Книга завершена"
                      ></v-checkbox>
                      <v-dialog
                        v-model="bookFinishModal"
                        class="text-center"
                        max-width="450"
                      >
                        <v-card>
                          <v-card-title class="headline justify-center"
                            >Вы уверены?</v-card-title
                          >

                          <v-card-text class="text-center"
                            >Что хотите завершить книгу, Вы не сможете большее
                            ее редактировать.</v-card-text
                          >

                          <v-card-actions class="justify-space-around">
                            <v-btn
                              color="red darken-1"
                              text
                              @click="closeFinishModal(false)"
                              >Да</v-btn
                            >

                            <v-btn
                              color="green darken-1"
                              text
                              @click="closeFinishModal(true)"
                              >Нет</v-btn
                            >
                          </v-card-actions>
                        </v-card>
                      </v-dialog> -->
                      <v-btn v-if="bookId" dark color="primary" @click="submit"
                        >Обновить</v-btn
                      >
                      <v-btn v-else dark color="primary" @click="submit"
                        >Сохранить</v-btn
                      >
                    </v-col>
                  </v-row>
                </v-form>
              </v-card>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item value="tab-chapters">
          <div v-if="!status" class="error--text text-center">
            Ваша книга не опубликована -
            <a @click="bookTab = 'tab-edit'">опубликуйте книгу.</a>
          </div>
          <v-row v-if="chapters.length == 0" align="center" justify="center">
            <no-content
              route-name="CabinetChapterAdd"
              :route-params="{ bookId: bookId }"
              title="В книге еще нет глав"
            ></no-content>
          </v-row>

          <div v-if="chapters.length > 0" class="draggable">
            <draggable
              animation="400"
              handle=".drag-handle"
              v-model="chapters"
              tag="div"
              @change="orderChaptersUpdate"
            >
              <chapter-card
                v-for="(item, index) in chapters"
                :key="item.id"
                :item="item"
                :index="index"
                :dellLoader="dellChapterLoader"
                @remove-chapter-modal="deleteChapterModal"
              ></chapter-card>
            </draggable>
          </div>
          <v-row align="center" justify="center">
            <v-col cols="12" md="8">
              <v-subheader class="px-0">Загрузить книгу файлом</v-subheader>
              Файл должен быть в формате .docx (Документ Microsoft Word) вида
              Глава 1 текст Глава 2 текст. Слово Глава должно быть с новой
              строчки. Если у вас есть Пролог и Эпилог, они войдут в первую и
              последнюю главу соответственно. После загрузки книги вы сможете
              вытащить их и оформить в отдельные главы вручную. Внимание! Если
              загрузка прошла, а главы не появились, перегрузите страницу!
              <!-- <v-subheader class="px-0"
                >Внимание! Главы в вашей книге должны быть разделены
                конструкцией "-|||-"</v-subheader
              >
              <router-link
                :to="{ name: 'InfoShow', params: { slug: 'load-book' } }"
                >Инструкция как подготовить книгу для загрузки</router-link
              > -->
              <v-file-input
                v-model="fileDocx"
                clearable
                :loading="loadingDocx"
                :disabled="disabledDocx"
                accept=".docx"
                :rules="docxRules"
                @change="uploadeDocx"
                :success-messages="successDocx"
                label="Выберите файл .docx"
              ></v-file-input>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item value="tab-coauthors">
          <coauthors-tab></coauthors-tab>
        </v-tab-item>
        <v-tab-item value="tab-filters">
          <dop-filters></dop-filters>
        </v-tab-item>
        <v-tab-item value="tab-promo">
          <promo></promo>
        </v-tab-item>
        <v-tab-item value="tab-discount">
          <discount></discount>
        </v-tab-item>
        <v-tab-item value="tab-loyalty">
          <loyalty></loyalty>
        </v-tab-item>
      </v-tabs-items>
      <v-dialog
        v-model="dialogDeleteChapter"
        class="text-center"
        max-width="290"
      >
        <v-card>
          <v-card-title class="headline justify-center"
            >Вы уверены?</v-card-title
          >

          <v-card-text>Что хотите удалить данную запись.</v-card-text>

          <v-card-actions class="justify-space-around">
            <v-btn color="red darken-1" text @click="deleteChapter">Да</v-btn>

            <v-btn
              color="green darken-1"
              text
              @click="dialogDeleteChapter = false"
              >Нет</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>


      <v-dialog
        v-model="dialogDeleteAllChapters"
        class="text-center"
        max-width="290"
      >
        <v-card>
          <v-card-title class="headline justify-center"
            >Вы уверены?</v-card-title
          >

          <v-card-text>Что хотите удалить все главы.</v-card-text>

          <v-card-actions class="justify-space-around">
            <v-btn color="red darken-1" text @click="deleteAllChapters">Да</v-btn>

            <v-btn
              color="green darken-1"
              text
              @click="dialogDeleteAllChapters = false"
              >Нет</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
    <partner-dialog ref="partnerDialog"></partner-dialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import ChapterCard from "./ChapterCard.vue";
import CoauthorsTab from "./CoauthorsTab.vue";
import SalesRequest from "./SalesRequest.vue";
import DopFilters from "./DopFilters.vue";
import Promo from "./Promo.vue";
import Discount from "./Discount.vue";
import Loyalty from "./Loyalty.vue";
import draggable from "vuedraggable";
import PartnerDialog from "@/components/partner/PartnerDialog.vue";
export default {
  components: {
    ChapterCard,
    draggable,
    SalesRequest,
    CoauthorsTab,
    DopFilters,
    Promo,
    Discount,
    Loyalty,
    PartnerDialog,
  },
  data: () => ({
    changecategories: [],
    tags: [],
    serie_id: "",
    delimitersTags: [",", ".", "/"], //Все теги
    imageData: "",
    bookId: null, //Id серии которую будем редактировать
    valid: true,
    categoriesError: false,
    image: "",
    title: "",
    // topTitle: "",
    excerpt: "", //Аннотация
    yt: "", //ссылка на видео youtube
    notes: "", //Примечание
    fragment: 0,

    // fake_fragment: 0,
    adult: 0,
    status: 0,
    finish: 0,
    can_comment: 1,
    can_download: 0,
    requiredRules: [(v) => !!v || "Поле обязательно"],
    titleRules: [
      (v) => !!v || "Поле обязательно",
      (v) => v.length >= 3 || "Минимум 3 символа",
    ],
    priceRules: [
      //   v => !!v || "Поле обязательно",
      (v) => v >= 50 || "Цена должна быть от 50 руб. до 150 руб.",
      (v) => v <= 150 || "Цена должна быть от 50 руб. до 150 руб.",
      // (v) => !v || /^[\d]*$/.test(v) || "Только цифры",
      // (v) =>
      //   (!!v === v >= 60) === v <= 80 ||
      //   "Цена должна быть от 50 руб. до 150 руб.",
    ],
    tagsRules: [
      (v) =>
        (v.length <= 4 && v.length > 2) || "Минимум 3 тега, максимум 4 тега",
    ],
    excerptRules: [
      // v => !!v || 'Поле обязательно',
      (v) =>
        (!!v === v.length >= 10) === v.length <= 1200 ||
        "Минимум 10 символов, максимум 1200 символов",
    ],
    ytRules: [
      // v => !!v || 'Поле обязательно',
      (v) =>
        (!!v === v.length >= 10) === v.length <= 100 ||
        "Минимум 10 символов, максимум 100 символов",
    ],
    imageRules: [
      (value) =>
        !value ||
        value.size < 2000000 ||
        "Размер файла не должен превышать 2 MB!",
    ],

    bookTab: "tab-edit",
    dialogDeleteChapter: false,
    dialogDeleteAllChapters: false,
    deleteChapterData: null,
    // chapters: [],
    chpid: "", //Номер главы с которой будет платный контент
    price: 0, //Цена
    paid: 0, //Платная книга или нет
    salesAllowed: 0, //Продажи разрешены или нет, те заключен договор по этой книге или нет
    fileDocx: null,
    loadingDocx: false,
    disabledDocx: false,
    successDocx: "",
    docxRules: [
      (v) =>
        !v || v.size < 10000000 || "Рзмер файла не должен превышать 10 MB!",
    ],
    bookFinishModal: false,
    dellChapterLoader: false,
  }),
  methods: {
    openBookFinishModal(newValue) {
      if (newValue) {
        this.bookFinishModal = true;
      }
    },
    closeFinishModal(accept) {
      this.bookFinishModal = false;
      if (accept) {
        this.finish = false;
      }
    },
    orderChaptersUpdate() {
      this.chapters.map((item, index) => {
        item.order = index + 1;
        index = item.order;
      });
      //event.preventDefault();
      this.loading = true;
      let app = this;
      this.$store.dispatch("cabinet_books/updateOrder", {
        book_id: app.bookId,
        chapters: app.chapters,
      });
    },

    validate() {
      return this.$refs.form.validate();
    },

    deleteChapterModal(data) {
      this.deleteChapterData = data;
      this.dialogDeleteChapter = true;
    },
    deleteAllChaptersModal() {
      this.dialogDeleteAllChapters = true;
    },
    deleteChapter() {
      // this.loading = true;
      let app = this;
      this.dellChapterLoader = true;
      app.dialogDeleteChapter = false;
      this.$store
        .dispatch("cabinet_chapters/dellChapter", {
          deleteData: app.deleteChapterData,
        })
        .then(() => {
          this.dellChapterLoader = false;
        })
        .catch(() => {
          this.dellChapterLoader = false;
        });
    },
    deleteAllChapters() {
      // this.loading = true;
      let app = this;
      this.dellChapterLoader = true;
      app.dialogDeleteAllChapters = false;
      this.$store
        .dispatch("cabinet_chapters/dellAllChapters", {
           book_id: app.bookId, 
        })
        .then(() => {
          this.dellChapterLoader = false;
        })
        .catch(() => {
          this.dellChapterLoader = false;
        });
    },
    uploadeImage(event) {
      var input = event.target;
      if (input.files && input.files[0]) {
        this.image = input.files[0];
        if (this.bookId) {
          this.uploadCover();
        }

        var reader = new FileReader();
        reader.onload = (e) => {
          this.imageData = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
      }
    },
    submit() {
      if (!this.validate() || this.categoriesError) return;
      let app = this;
      let data = {
        title: app.title,
        serie_id: app.serie_id,
        categories: JSON.stringify(app.changecategories),
        tags: JSON.stringify(app.tags),
        excerpt: app.excerpt,
        yt: app.yt,
        // image: app.image,
        notes: app.notes,
        fragment: app.fragment,
        // fake_fragment: app.fake_fragment,
        adult: app.adult,
        status: app.status,
        finish: app.finish,
        can_download: app.can_download,
        can_comment: app.can_comment,
        chpid: app.chpid,
        // price: app.price,
        // paid: app.paid,
      };
      let formData = new FormData();

      for (let key in data) {
        formData.append(key, data[key]);
      }
      if (this.bookId) {
        formData.append("_method", "PUT"); //костыль для laravel
        this.$store.dispatch("cabinet_books/updateBook", {
          book_id: app.bookId,
          formData: formData,
        });
      } else {
        formData.append("image", this.image);
        this.$store
          .dispatch("cabinet_books/addBook", {
            formData: formData,
          })
          .then((response) => {
            app.$router.push({
              name: "CabinetBookEdit",
              params: { id: response.data.book.id },
            });
          });
      }
    },
    uploadCover() {
      let app = this;
      if (!app.bookId) return;
      let formData = new FormData();
      formData.append("image", this.image);
      formData.append("_method", "PUT"); //костыль для laravel
      // let app = this;
      this.$store.dispatch("cabinet_books/updateCover", {
        book_id: app.bookId,
        formData: formData,
      });
    },

    getBook() {
      let app = this;
      this.$store
        .dispatch("cabinet_books/getEditBook", {
          book_id: app.bookId,
        })
        .then(() => {
          this.checkTab();
        });
    },
    checkTab() {
      let routeTab = this.$route.query.tab;
      // console.log(routeTab);
      if (routeTab === "edit") {
        this.bookTab = "tab-edit";
        if (this.$route.hash) {
          this.$vuetify.goTo(this.$route.hash);
        }
      } else if (routeTab === "loyalty") {
        this.bookTab = "tab-loyalty";
      } else {
        this.bookTab = "tab-chapters";
      }

      console.log(this.bookTab);
    },
    getStart() {
      this.$store.dispatch("cabinet_books/getStart");
    },
    clear() {
      this.changecategories = [];
      this.serie_id = "";
      this.tags = [];
      this.imageData = "";
      this.bookId = null; //Id серии которую будем редактировать
      this.image = "";
      this.title = "";
      this.excerpt = ""; //Аннотация
      this.yt = "";
      this.notes = ""; //Примечание
      this.fragment = "0";
      // this.fake_fragment = "0";
      this.adult = "0";
      this.status = "0";
      this.finish = "0";
      this.can_download = "0";
      this.can_comment = "1";
    },
    updateData() {
      // this.topTitle = this.book.title;
      if (this.book) {
        this.title = this.book.title;
        this.serie_id = this.book.serie_id ? +this.book.serie_id : "";
        this.excerpt = this.book.excerpt ? this.book.excerpt : "";
        this.yt = this.book.yt ? this.book.yt : "";
        this.changecategories = this.book.categories_ids
          ? this.book.categories_ids
          : [];
        // this.chapters = this.book.chapters ? this.book.chapters : [];
        this.tags = this.book.tags_names ? this.book.tags_names : [];
        this.imageData = this.book.image_big ? this.book.image_big : "";
        // this.image = this.book.image;
        this.notes = this.book.notes ? this.book.notes : "";
        this.fragment = +this.book.fragment;
        // this.fake_fragment = +this.book.fake_fragment;
        this.adult = +this.book.adult;
        this.status = +this.book.status;
        this.finish = +this.book.finish;
        this.can_download = +this.book.can_download;
        this.can_comment = +this.book.can_comment;
        this.chpid = this.book.chpid !== null ? +this.book.chpid : "";
        this.price = this.book.price !== null ? +this.book.price : 0;
        this.paid = +this.book.paid;
        this.salesAllowed = +this.book.sales_allowed;
      }
    },
    showCountCategoriesMessage(count) {
      this.$eventBus.$emit("show-modal-error", [
        true,
        { message: "Максимальное число категорий " + count },
        "error",
      ]);
    },
    checkCategory() {
      //Смотрм если последняя категория имеет родителя, его тоже надо отметить
      let catId = +this.changecategories[this.changecategories.length - 1];
      let findCat;
      if (catId && this.categories) {
        findCat = this.findCategory(catId, this.categories);

        if (findCat.parent_id && findCat.parent_id != "null") {
          // console.log('А вот и батя', findCat.parent_id);
          if (!this.changecategories.includes(+findCat.parent_id)) {
            this.changecategories.push(+findCat.parent_id);
          }
        }
      }
    },

    findCategory(id, categories) {
      //Рекурсивный поиск нужной нам категории
      let findCat;
      for (let i = 0; i < categories.length; i++) {
        let category = categories[i];
        // console.log('category.id',category.id)
        // console.log('id',id)
        if (category.id == id) {
          findCat = category;
          break;
        }

        if (category.children && category.children.length > 0) {
          findCat = this.findCategory(id, category.children);
          if (findCat) {
            break;
          }
        }
      }
      return findCat;
    },
    checkCategoryAdult() {
      let eroArr = [35, 36, 37, 38, 39, 40]; //Категоррии эротики
      for (let i = 0; i < eroArr.length; i++) {
        if (this.changecategories.includes(eroArr[i])) {
          this.adult = 1;
          break;
        }
      }
    },
    uploadeDocx() {
      this.loadingDocx = true;
      this.disabledDocx = true;
      this.successDocx = "";
      let formData = new FormData();
      formData.append("file", this.fileDocx);
      formData.append("book_id", this.book.id);
      formData.append("_method", "PUT"); //костыль для laravel
      let app = this;
      this.$store
        .dispatch("cabinet_chapters/bulkUploadChapterFile", formData)
        .then((responce) => {
          app.body = app.body + responce.data.convert;
          app.successDocx =
            "Данные успешно добавлены, не забудьте проверить и сохранить изменения!";
          app.clearDocx();
        })
        .catch(() => {
          app.clearDocx();
        });
    },
    clearDocx() {
      this.fileDocx = null;
      this.loadingDocx = false;
      this.disabledDocx = false;
    },
    onChangeTags() {
      if (this.tags.length > 0) {
        // let newTags = this.tags.map(
        //   Function.prototype.call,
        //   String.prototype.trim
        // );
        let newTags = this.tags;
        let newStr = "";
        newStr = newTags.join(",");
        newTags = newStr.split(",");
        newStr = newTags.join(".");
        newTags = newStr.split(".");
        newStr = newTags.join("/");
        newTags = newStr.split("/");

        let newTags2 = newTags.reduce((memo, tag) => {
          // if condition is our filter
          if (tag && tag != " " && tag != undefined && tag.length > 3) {
            // what happens inside the filter is the map
            tag.trim();
            memo.push(tag);
          }
          return memo;
        }, []);
        this.tags = newTags2;
      }
    },
 
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      loadPage: "loadPage",
      book: "cabinet_books/book",
      series: "cabinet_books/series",
      alltags: "cabinet_books/alltags",
      categories: "cabinet_books/categories",
      chapters_public: "cabinet_chapters/chapters_public",
      // chapters: "cabinet_chapters/chapters",
    }),
    chapters: {
      get() {
        return this.$store.getters["cabinet_chapters/chapters"];
      },
      set(value) {
        this.$store.commit("cabinet_chapters/GET_CHAPTERS", value);
      },
    },
    topTitle() {
      // <v-toolbar-title v-if="bookId">{{ $vuetify.breakpoint.smAndUp ? 'Редактировать '+topTitle : topTitle }}</v-toolbar-title>
      // <v-toolbar-title v-else>{{ $vuetify.breakpoint.smAndUp ? 'Добавить произведение' : 'Добавить' }}</v-toolbar-title>
      let title = "Добавить";
      if (this.bookId && this.book && this.book.title) {
        title = "Редактировать";
        if (this.$vuetify.breakpoint.smAndUp) {
          title = title + " " + this.book.title;
        }
      } else {
        if (this.$vuetify.breakpoint.smAndUp) {
          title = title + " произведение";
        }
      }

      return title;
    },
  },
  watch: {
    book() {
      if (this.book) {
        this.updateData();
      }
    },
    changecategories() {
      // if (this.changecategories) {
      let count = 4;
      // console.log('this.changecategories',this.changecategories)
      // console.log('this.changecategories.length',this.changecategories.length)
      if (this.changecategories.length > count) {
        // this.showCountCategoriesMessage(count);
        this.categoriesError = true;
        // this.changecategories.pop();
      } else {
        this.categoriesError = false;
      }
      // console.log('new',newc);
      // console.log('old',oldc);
      this.checkCategory();
      this.checkCategoryAdult();
      // }
    },
  },
  mounted() {
    this.bookId = this.$route.params.id;

    if (this.bookId) {
      // this.bookTab =
      //   this.$route.query.tab == "edit" ? "tab-edit" : "tab-chapters";
      this.getBook();
    } else {
      this.getStart();
    }
    if (this.$route.query.p_dialog) {
      this.$refs.partnerDialog.open();
      //  console.log(this.$route.query)
    }

    //Если имеется id - редактирование, нету - добавление
  },
};
</script>
