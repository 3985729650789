<template>
  <v-col class="text-left" cols="12" md="8">
    <v-card dense class="mx-auto mb-2">
      <div class="drag-card">
        <div class="drag-nav">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" icon>
                <v-icon class="drag-handle">$vuetify.icons.drag</v-icon>
              </v-btn>
            </template>
            <span>Изменить порядок</span>
          </v-tooltip>
        </div>
        <div class="drag-content">
          <div class="chapter-content">
            <v-card-title class="chapter-title" v-text="item.title"></v-card-title>
            <div
              class="chapter-status"
              :class="+item.status ? 'success--text' : ''"
            >{{ +item.status ? 'опубликована' : 'черновик' }}</div>
            <div class="chapter-count-char">{{ item.count_char }} зн.</div>
          </div>
          <v-spacer></v-spacer>
          <v-btn v-if="book" :to="{name: 'ReaderShowFull', params: {bookSlug: book.slug, id:item.id}}" icon>
            <v-icon>$vuetify.icons.eye</v-icon>
          </v-btn>
          <v-btn :to="{name: 'CabinetChapterEdit', params: { id: item.id } }" icon>
            <v-icon>$vuetify.icons.square_edit_outline</v-icon>
          </v-btn>
          <v-btn :loading="dellLoader" @click.stop="deleteChapter" icon>
            <v-icon>$vuetify.icons.delete</v-icon>
          </v-btn>
        </div>
      </div>

      <!-- <v-card-actions></v-card-actions> -->
    </v-card>
  </v-col>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props: ["item", "index", "dellLoader"],
  data() {
    return {};
  },
  methods: {
    deleteChapter() {
      this.$emit("remove-chapter-modal", [this.item.id, this.index]);
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      loadPage: "loadPage",
      book: "cabinet_books/book",
      series: "cabinet_books/series",
      alltags: "cabinet_books/alltags",
      categories: "cabinet_books/categories",
      // chapters: "cabinet_chapters/chapters",
    }),
  }
};
</script>
