<template>
  <v-row align="center" justify="center">
    <v-col cols="12" md="8">
      <v-card class="pa-4 mb-4">
        <v-form v-model="valid" ref="form">
          <v-checkbox
            v-model="check_loyalty"
            label="Участвовать в программе лояльности"
          ></v-checkbox>
          <v-subheader class="pl-0">% скидки.</v-subheader>
          <v-slider
            :disabled="!check_loyalty"
            v-model="percent"
            min="10"
            max="40"
            :label="percent + ' %.'"
          >
          </v-slider>
          <v-btn
            @click="sendLoyalty"
            class=""
            color="primary"
            dark
            :loading="addLoading"
            >Сохранить</v-btn
          >
        </v-form>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data: () => ({
    check_loyalty: 0,
    valid: true,
    addLoading: false,
    percent: 40,
    requiredRules: [(v) => !!v || "Поле обязательно"],
  }),
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    updateData() {
      if (this.book && this.loyalty) {
        if (this.loyalty === 0) {
          this.check_loyalty = 0;
        } else {
          this.percent = this.loyalty;
          this.check_loyalty = 1;
        }
      }
    },
    sendLoyalty() {
      if (this.check_loyalty) {
        this.updateLoyalty();
      } else {
        this.dellLoyalty();
      }
    },
    updateLoyalty() {
      if (!this.validate()) return;
      let app = this;
      app.addLoading = true;
      this.$store
        .dispatch("cabinet_books/updateLoyalty", {
          book_id: app.book.id,
          percent: app.percent,
        })
        .then(() => {
          app.addLoading = false;
        })
        .catch(() => {
          app.addLoading = false;
        });
    },
    dellLoyalty() {
      let app = this;
      app.addLoading = true;
      this.$store
        .dispatch("cabinet_books/dellLoyalty", {
          book_id: app.book.id,
        })
        .then(() => {
          app.addLoading = false;
        })
        .catch(() => {
          app.addLoading = false;
        });
    },
  },
  computed: {
    ...mapGetters({
      // currentUser: "currentUser",
      // loadPage: "loadPage",
      book: "cabinet_books/book",
      loyalty: "cabinet_books/book_loyalty",
    }),
  },
  watch: {
    loyalty() {
      this.updateData();
    },
  },
  mounted() {
    this.updateData();
  },
};
</script>