<template>
  <div>
    <v-row align="center" justify="center">
      <v-col cols="12" md="8">
        <v-form v-model="valid" ref="form">
          <v-autocomplete
            v-model="coauthor"
            :items="authors"
            item-text="public_name"
            item-value="id"
            label="Добавить соавтора"
            :rules="requiredRules"
          >
            <template v-slot:item="data">
              <template>
                <v-list-item-avatar>
                  <img :src="data.item.avatar_url" />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title
                    v-html="data.item.public_name"
                  ></v-list-item-title>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
          <v-checkbox v-model="checkbox" :rules="requiredRules">
            <template v-slot:label>
              <div>
                Я согласен с
                <router-link
                  :to="{
                    name: 'Page',
                    params: { slug: 'pravila-dobavleniya-soavtora' },
                  }"
                  >правилами</router-link
                >
                добавления соавтора
              </div>
            </template>
          </v-checkbox>
          <v-btn dark color="primary" :loading="addLoader" @click="submit"
            >Добавить</v-btn
          >
        </v-form>
      </v-col>
    </v-row>
    <v-row v-if="coauthors.length > 0" align="center" justify="center">
      <coauthor-card
        v-for="(item, index) in coauthors"
        :key="item.id"
        :item="item"
        :index="index"
        @remove-coauthor-modal="deleteCoauthorModal"
      ></coauthor-card>
    </v-row>

    <v-dialog
      v-model="dialogDeleteCoauthor"
      class="text-center"
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline justify-center">Вы уверены?</v-card-title>

        <v-card-text>Что хотите удалить данную запись.</v-card-text>

        <v-card-actions class="justify-space-around">
          <v-btn color="red darken-1" text @click="deleteCoauthor">Да</v-btn>

          <v-btn
            color="green darken-1"
            text
            @click="dialogDeleteCoauthor = false"
            >Нет</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import CoauthorCard from "./CoauthorCard.vue";
export default {
  components: { CoauthorCard },
  data: () => ({
    coauthor: null,
    addLoader: false,
    valid: true,
    checkbox: false,
    requiredRules: [(v) => !!v || "Поле обязательно"],

    dialogDeleteCoauthor: false,
    deleteCoauthorData: null,
  }),
  methods: {
    validate() {
      return this.$refs.form.validate();
    },

    deleteCoauthorModal(data) {
      this.deleteCoauthorData = data;
      this.dialogDeleteCoauthor = true;
    },
    deleteCoauthor() {
      // this.loading = true;
      let app = this;
      app.dialogDeleteCoauthor = false;
      this.$store.dispatch("cabinet_books/dellCoauthor", {
        user_id: app.deleteCoauthorData,
        book_id: app.book.id,
      });
    },

    submit() {
      if (!this.validate()) return;
      let app = this;
      app.addLoader = true;
      //console.log(app.coauthor);
      this.$store
        .dispatch("cabinet_books/addCoauthor", {
          book_id: app.book.id,
          user_id: app.coauthor,
        })
        .then(() => {
          app.addLoader = false;
        })
        .catch(() => {
          app.addLoader = false;
        });
    },
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      loadPage: "loadPage",
      book: "cabinet_books/book",
      authors: "cabinet_books/authors",
      coauthors: "cabinet_books/coauthors",
    }),
  },
};
</script>
