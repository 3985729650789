<template>
  <v-col class="text-left" cols="12" md="8">
    <v-card dense class="mx-auto mb-2">
      <div class="author-content">
        <div class="author-avatar">
        <img :src="item.avatar_url" />
      </div>
      <div class="author-content-right">
        <div
          class="author-title"
          v-text="item.public_name"
        ></div>
      </div>
      </div>
      <v-card-actions class="mt-auto">
        <v-spacer></v-spacer>
        <v-btn
          :to="{
            name: 'UserShow',
            params: { userName: item.name },
          }"
          icon
        >
          <v-icon>$vuetify.icons.eye</v-icon>
        </v-btn>

        <v-btn @click.stop="deleteCoauthor" icon>
          <v-icon>$vuetify.icons.delete</v-icon>
        </v-btn>
      </v-card-actions>
      <!-- <v-card-actions></v-card-actions> -->
    </v-card>
  </v-col>
</template>
<script>
export default {
  props: ["item", "index"],
  data() {
    return {};
  },
  methods: {
    deleteCoauthor() {
      this.$emit("remove-coauthor-modal", this.item.id);
    },
  },
  computed: {
 
  },
};
</script>
